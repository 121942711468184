.datenschutz{
	width: 100%;
	height: auto;
	padding-top: 25vw;
}

.datenschutz-title{
	font-family: Poppins-Bold;
	text-align: center;
	padding: 12vw 0;
	font-size: 8vw;
	font-weight: 900;
}

.datenschutz-content{
	font-family: Poppins-Regular;
	margin-bottom: 150px;
}

.black-circle{
	display: none;
}

@media only screen and (min-width: 992px){
	.datenschutz{
		padding-top: 0;
	}

	.datenschutz-title{
		padding: 5rem 9rem;
		padding-top: 10rem;
		padding-left: 10vw;
		padding-bottom: 4rem;
		font-family: Poppins-Bold;
		font-size: 1.5rem;
		letter-spacing: 1.8px;
		text-align: left;
	}

	.datenschutz-content{
		margin-bottom: 250px;
		padding: 0;
		padding-left: 13rem;
		padding-right: 12rem;
	}

	.black-circle{
		display: initial;
		position: absolute;
		top: 2.5rem;
		right: 15%;
		width: 25px;
		height: 25px;
		transition: all .2s ease;
	}

		.big-title{
			font-family: Bold;
			font-size: 0.9rem;
			letter-spacing: 1.8px;
			height: 25vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 7vh;
			padding-left: 9rem;
		}
		.black-circle{
		}

	.black-circle:hover{
		transform: scale(1.07);
	}
}