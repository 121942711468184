.def-button {
	width: 220px;
  	height: 40px;
  	border: solid 1px #1d0a96;
	font-family: Poppins-Bold;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: center;
	color: #1d0a96;	
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 200ms ease-in-out;
	text-decoration: none;
}

.def-button:hover {
	cursor: pointer;
	transform: scale(1.035, 1.035);
	text-decoration: none;
	color: #1d0a96;	
}

@media only screen and (min-width: 992px){
	.def-button {
		width: 250px;
	  	height: 45px;
	  	border: solid 1px #1d0a96;
		font-family: Poppins-Bold;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: #1d0a96;	
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 200ms ease-in-out;
	}

	.def-button:hover {
		cursor: pointer;
		transform: scale(1.035, 1.035);
	}
}