.art {
	width: 100%;
	padding-left: 0;
	padding-top: 12vw;

	.Kunst {
		padding-left: 6.5vw;
		width: 100%;
		height: 112px;
		font-family: Poppins-Bold;
		font-size: 48px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		color: #000;
	}

	.items {
		padding-left: 6.5vw;
		padding-right: 6.5vw;
		width: 100%;
		overflow: auto;
		overflow-y: hidden;
	}
}

@media only screen and (min-width: 992px){
	.art {
		width: 100%;
		padding-left: 10vw;
		padding-top: 12vw;

		.Kunst {
			padding-left: 0;
			width: 889px;
			height: 112px;
			font-family: Poppins-Bold;
			font-size: 48px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.17;
			letter-spacing: normal;
			color: #000;
		}

		.items {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}
}