.termin-btn{
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all .25s ease;

	.wrapper{
		padding-right: 0 !important;
		position: relative;
		.circle{
			width: 2.5em;
			height: 2.5rem;
			border-radius: 50%;
		}

		.line{
			position: absolute;
			top: 1.25rem;
			left: 1.25rem;
			width: 2rem;
			animation-name: lineAnimation;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
		}
	}

	.termin-btn-text{
		font-family: Poppins-Bold;
		font-size: 0.75rem;
		line-height: 1.125rem;
		letter-spacing: 0.0075rem;
		text-transform: uppercase;
		white-space: nowrap;
		text-center: left;
		padding-left: 2rem;
	}
}

@keyframes lineAnimation{
	0%{
		left: 1.25rem;
		width: 0rem;
	}

	50%{
		left: 1.25rem;
		width: 2rem;
	}

	100%{
		left: 3.25rem;
		width: 0;
	}
}

@media only screen and (min-width: 992px){
	.termin-btn{
		padding-left: 0;
	}

	.termin-btn:hover {
		transform: scale(1.02,1.02);
	}
}
