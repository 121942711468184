.panorama {
	max-width: 100vw;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,.8);
	z-index: 100000;
}

.exit-icon{
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 100001;
	width: 50px !important;
	height: 50px;
	color: white;
	animation: pulse 2s infinite;
	cursor: pointer;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	50% {
		transform: scale(1.15);
	}

	100% {
		transform: scale(0.95);
	}
}