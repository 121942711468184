.background-wrapper{
	min-height: 90vh;
	background-image: url('/footer.png');
	background-repeat: no-repeat;
	background-size: cover;
	.footer{
		min-height: 90vh;
		width: 100%;
		background-color: rgba(29, 10, 153, .65);

		.header{
			height: 17vh;
			.newsletter-logo{
				width: 13.544rem;
				height: 4.92rem;
				margin-left: auto;
				margin-right: auto;
				margin-top: 2rem;
			}
		}
		.copyright{
			font-family: Poppins-Regular;
			height: 40px;
			font-size: .9rem;
			color: white;
		}
		.content{
			.links-wrapper{
				height: 100%;
				.links{
					position: relative;
					bottom: 0;
					height: 100%;
					.link{
						font-family: Poppins-Bold;
						font-stretch: expanded;
						font-size: 0.75rem;
						letter-spacing: 0.1875rem;
						color: white;
						text-decoration: none;
						margin-bottom: 1rem;
					}
				}

				.location-info {
					padding-bottom: 8vw;
					padding-top: 10vw;

					.location-info-address {
						text-align: center;
					    font-family: Poppins-Regular;
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.29;
						letter-spacing: normal;
						color: #fff;
					}
				}
			}
			.divider{
				height: 28%;
				padding: 0;
				padding-bottom: 1.5rem;
				.left{
					border-right: 0.1px solid #ffffff;
					opacity: 0.4;
				}
			}
			.newsletter-wrapper{
				.newsletter{
					height: 45%;

					.newsletter-title{
						font-family: Poppins-Bold;
						font-size: 0.95rem;
						color: white;
						text-decoration: none;
						padding-bottom: 1.5rem;
					}

					.btn-wrapper{
						position: relative;
						padding: 0;
						padding-top: 2rem;
						padding-bottom: 2rem;
					}

					.info{
						font-family: Poppins-Regular;
						position: relative;
						font-size: 0.75rem;
						line-height: 1rem;
						color: white;
						text-decoration: none;
						padding-bottom: 1.5rem;

						.success-message{
							font-family: Poppins-Regular;
							position: absolute;
							bottom: 0;
							white-space: nowrap;
							left: 0;
							right: 0;
							color: red;
							font-size: 14px;
						}

						.newsletter-error{
							font-family: Poppins-Regular;
							position: absolute;
							bottom: 0;
							white-space: nowrap;
							left: 0;
							right: 0;
							color: red;
							font-size: 14px;
						}
					}

					.newsletter-input{
						border: 0;
						outline: 0;
						border-bottom: 1px solid #FFFFFF61;
						background-color: transparent;
						color: #FFFFFFAA;
						font-style: italic;
					}
					.newsletter-input::placeholder{
						font-size: 0.8rem;
						font-style: italic;
						color: white;
						opacity: 0.5;
					}
					.confirm{
						font-family: Poppins-Regular;
						color: white;
						font-size: 0.625rem;
						text-transform: uppercase;
						margin-top: 1rem;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 992px){
	.background-wrapper{
		height: 80vh;
		min-height: 80vh;
		background-image: url('/footerXL.png');
		.footer{
			min-height: 100%;
			height: 100%;
			.header{
				height: 22%;
				.newsletter-logo{
					margin: 0;
					margin-left: auto;
					margin-right: auto;
					width: 10rem;
					height: 3.9rem;
				}
			}
			.content{
				padding-bottom: 6rem;
				.links-wrapper{
					position: relative;
					height: 100%;
					width: 30%;
					.links{
						height: auto;	
						.link{
							font-family: Poppins-Bold;
							font-size: 12px;
							cursor: pointer;
							transition: all .3s ease;
						}

						.link:hover{
							transform: scale(1.05);
						}
					}

					.location-info {
						padding-bottom: 0;
						padding-top: 0;

						.location-info-title {
							font-family: Poppins-Regular;
							font-size: 13px;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 3.33;
							letter-spacing: 4px;
							color: #fff;
						}

						.location-info-address {
							text-align: left;
						    font-family: Poppins-Regular;
							font-size: 14px;
							font-weight: normal;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.29;
							letter-spacing: normal;
							color: #fff;
						}
					}
				}
				.divider{
					height: 100%;
					width: 15%;
					padding-top: 2.5rem;
					padding-bottom: 0;
				}
				.newsletter-wrapper{
					position: relative;
					width: 36%;
					.newsletter{
						height: auto;
						position: absolute;
						bottom: 0;
						.newsletter-title{
							font-family: Poppins-Regular;
							font-size: 0.85rem;
						}
						.info{
							font-size: 0.8rem;
							.newsletter-error{
								font-size: 0.9rem;
							}
							.success-message{
								font-size: 0.9rem;
							}
						}
						.btn-wrapper{
							position: relative;
							padding: 0;
							padding-left: 4rem;
						}
						.newsletter-input{
							margin-bottom: 2.5rem;
						}
					}
				}
			}
			.copyright{
				font-family: Poppins-Regular;
				font-size: 0.75rem;
			color: #FFFFFF;
				height: 10%;
				margin-top: 0;
			}
		}
	}
}

@media only screen and (min-width: 1200px){
	.background-wrapper{
		background-image: url('/footerXL.png');
		.footer{
			.header{
				height: 22%;
				.newsletter-logo{
					margin: 0;
					margin-left: auto;
					margin-right: auto;
					width: 13.544rem;
					height: 4.92rem;
				}
			}
			.content{
				padding-bottom: 6rem;
				.links-wrapper{
					position: relative;
					height: 100%;
					width: 30%;
					.links{
						height: auto;	
						.link{
							font-family: Poppins-Bold;
							font-size: 12px;
							cursor: default;
							transition: all .3s ease;
							cursor: pointer;
						}

						.link:hover{
							transform: scale(1.05);
						}
					}
				}
				.divider{
					height: 100%;
					width: 15%;
					padding-top: 4.5rem;
					padding-bottom: 0;
				}
				.newsletter-wrapper{
					position: relative;
					width: 30%;
					.newsletter{
						height: auto;
						position: absolute;
						bottom: 0;
						.newsletter-title{
							font-size: .9rem;
							letter-spacing: .25rem;
						}
						.info{
							padding-left: 2.5rem;
							font-size: 0.875rem;
							.newsletter-error{
								font-size: 0.9rem;
							}
							.success-message{
								font-size: 0.9rem;
							}
						}
						.btn-wrapper{
							position: relative;
							padding: 0;
							padding-left: 4rem;
						}
						.newsletter-input{
							margin-bottom: 2.5rem;
						}
					}
				}
			}
			.copyright{
				font-family: Poppins-Regular;
				font-size: 0.875rem;
			color: #FFFFFF;
				height: 10%;
				margin-top: 0;
			}
		}
	}
}
