.card {
    flex: 1;
    margin: 15px;
    box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.1);
    display: block;

    @media (min-width: 992px) {
        max-width: 320px;
        display: flex;
        flex-direction: column;
    }
}

.image-wrapper {
    background-color: #ffecec;
    height: 320px;
}

.details {
    padding: 26px 23px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: bold;
    font-family: Poppins-Bold;
    font-size: 14px;
    margin-bottom: 10px;
}

.description {
    font-size: 14px;
    margin-bottom: 30px;
    font-family: Poppins-Regular;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.price {
    margin-bottom: 10px;
}
