.partner {
	padding-top: 12vw;
	padding-bottom: 7vw;
	padding-left: 6.5vw;

	.title {
		font-family: Poppins-Bold;
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #000;
		padding-bottom: 13vw;
	}

	.partnerWrapper {
		width: 100%;
		padding-right: 50%;
		padding-bottom: 50%;

		.partnerImage {
			margin-bottom: 6vw;
		}

		.padLogo {
			width: 60%;
			opacity: .4;
		}

		.padioLogo {
			width: 60%;
		}
	}
}

@media only screen and (min-width: 992px){
	.partner {
		padding-top: 12vw;
		padding-bottom: 7vw;
		padding-left: 0;
		
		.title {
			font-family: Poppins-Bold;
			font-size: 48px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.17;
			letter-spacing: normal;
			text-align: center;
			color: #000;
			padding-bottom: 0;
		}

		.partnerWrapper {
			width: 100%;
			padding: 60px 150px;

			.partnerImage {
				margin-bottom: 0;
				transform: scale(.4);
			}

			.padLogo {
				opacity: 1;
			}
		}
	}
}