.about-page {
	position: relative;
	width: 100%;
	padding-bottom: 55vw;

	.about-part {
		padding-top: 10vw;
		width: 100%;
		
		.about-image {
			height: 11rem;
			min-width: 451px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
		}

		.about-text {
			padding-top: 8vw;
			padding-left: 7vw;
			padding-right: 12vw;

			.about-text-title {
				padding-bottom: 4vw;
				font-family: Poppins-Bold;
				font-size: 32px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.11;
				letter-spacing: normal;
				color: #000;
			}

			.about-text-description {
				font-family: Poppins-Regular;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;
			}
		}
	}

	.about-quote {
		width: 100%;
		padding-left: 7vw;
		padding-right: 12vw;
		padding-top: 28vw;

		.about-quote-text {
			font-family: Poppins-Regular;
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			color: #000;
		}

		.about-quote-author {
			padding-top: 7vw;
			padding-bottom: 17vw;
			font-family: Poppins-Regular;
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.14;
			letter-spacing: normal;
			text-align: left;
			color: #1d0a96;
		}
	}
}

@media only screen and (min-width: 992px){
	.about-page {
		position: relative;
		width: 100%;
		padding-bottom: 10vw;

		.about-part {
			padding-top: 5.5vw;
			width: 100%;
			padding-left: 10vw;
			padding-right: 10vw;
			
			.about-image {
				height: 560px;
				min-width: 451px;
				background-repeat: no-repeat;
				background-position: default;
				background-size: default;
			}

			.about-text {
				padding-top: 0;
				padding-right: 0;
				padding-left: 3vw;

				.about-text-title {
					padding-bottom: 2vw;
					font-family: Poppins-Bold;
					font-size: 38px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.11;
					letter-spacing: normal;
					color: #000;
				}

				.about-text-description {
					width: 92%;
					font-family: Poppins-Regular;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: #000;
				}
			}
		}

		.about-quote {
			width: 100%;
			padding-left: 10vw;
			padding-right: 10vw;
			padding-top: 6.5vw;

			.about-quote-text {
				font-family: Poppins-Regular;
				font-size: 28px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.14;
				letter-spacing: normal;
				text-align: center;
				color: #000;
			}

			.about-quote-author {
				padding-top: 2.5vw;
				padding-bottom: 0;
				font-family: Poppins-Regular;
				font-size: 28px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.14;
				letter-spacing: normal;
				text-align: center;
				color: #1d0a96;
			}
		}
	}
}