.pageHeader {
	margin-top: 90px;
	height: 217px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: 100% auto;

	&__description {
		max-width: 806px;
		padding: 0 30px;
		text-align: left;
		font-size: 14px;
		line-height: 1.25;

		@media screen and (min-width: 768px) {
			margin: 0 auto;
			font-size: 16px;
			text-align: center;
		}
	}
}



.pageHeader-title {
	font-family: Poppins-Bold;
	font-size: 32px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: center;
	color: #1d0a96;

	margin: 30px;
	text-align: left;
}

@media only screen and (min-width: 992px){
	.pageHeader {
		margin-top: 90px;
		width: 100%;
		height: 271px;
		background-size: 100% auto;
	}

	.pageHeader-title {
		font-family: Poppins-Bold;
		font-size: 48px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.17;
		letter-spacing: normal;
		text-align: center;
		color: #1d0a96;
	}
}