.calender-result-simple {
	width: 100%;
	padding-left: 5vw;
	padding-top: 5vw;
	padding-right: 10vw;

	.result-simple-date {
		font-family: Poppins-Regular;
	}

	.result-simple-title {
		font-family: Poppins-Bold;
	}

	.result-simple-description {
		font-family: Poppins-Regular;
		padding-bottom: 2vw;
	}
}

@media only screen and (min-width: 992px){
	.calender-result-simple {
		width: 65%;
		padding-left: 2vw;
		padding-top: 0;

		.result-simple-date {
			font-family: Poppins-Regular;
		}

		.result-simple-title {
			font-family: Poppins-Bold;
		}

		.result-simple-description {
			font-family: Poppins-Regular;
			padding-bottom: 2vw;
		}
	}
}