.slide {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}

.slider {
    position: relative;
    height: 240px;
    margin: 0 -30px 26px -30px;

    @media screen and (min-width: 576px) {
        height: 320px;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.stepnumbers {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 4px 18px;
    background-color: rgba($color: #000, $alpha: 0.6);
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 50%;
    border-radius: 13px;
    width: 60px;
}
