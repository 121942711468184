
.app-container{
	overflow-y: hidden;
	overflow-x: hidden;
	border: solid 1px rgba(0,0,0,.2);
}

html, body {
	max-width: 1400px;
	margin: auto;
	font-size: 4vw;
	overflow-x: hidden;
}

.app-font {
	font-family: Poppins-Regular;
}

.row{
	margin: 0;
	width: 100%;
}

.side-padding{
	padding: 0 2.75rem;
}

.app-font{
	font-family: "Univers LT W01_57 Condensed";
	font-size: 0.9rem;
	line-height: 1.2rem;
}


.bottom-marker{
	position: fixed;
	bottom: 0;
	width: 100%;
}

@media only screen and (min-width: 576px){
	html, body{
		font-size: 3vw;
	}

	.side-padding{
		padding: 0 5rem;
	}

	.app-font{
		font-size: 0.9rem;
		line-height: 1.3rem;
	}
}


@media only screen and (min-width: 992px){
	html, body{
		font-size: 1.5vw;
	}

	.side-padding{
		padding: 0 8rem;
	}

	.app-font{
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.large-hidden{
		width: 0;
	}
}

@media only screen and (min-width: 1200px){
	html, body{
		font-size: 16px;
	}

	.app-font{
		font-size: 1rem;
	}
}

@media only screen and (max-width: 991px){
	.hide-small{
		display: none;
	}
}

/*----------------all sizes------------------*/
.blue-text{
	color: #1D0A96 !important;
	-webkit-text-stroke: 0;
}

.arrow-wrapper{
	height: 4rem;
}
	
.arrow{
	width: 1.5rem;
}