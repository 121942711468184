.artworks {
    margin: 15px;
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }   

    @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.container {
    display: flex;
    justify-content: center;
    max-width: 1020px;
    margin: 0 auto;
    margin-bottom: 200px;
}

.filter-dropdown {
    margin: 30px 30px 60px 30px;
}