.gallery {
    display: none;
    
    @media screen and (min-width: 768px) {
        display: grid;
        gap: 10px;
        grid-template-columns: 193px 193px;
        grid-template-rows: 263px 143px;
    
        &>img {
            width: 100%;
            height: 100%;
            background-position: center;
            object-fit: cover;
        }
    
        &>img:first-child {
            grid-column: 1 / span 2;
        }
    }
}