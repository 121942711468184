.flats {
    max-width: 1020px;
    margin: 0 auto 200px;
    padding: 30px;
}

.filter-dropdown {
    margin: 30px 30px 60px 30px;
    display: block;

    @media screen and (min-width: 768px) {
        display: none;
    }
}