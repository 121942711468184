.home-item {
	height: 575px;
	min-width: 320px;
	max-width: 320px;
	background-color: black;
	margin-right: 4vw;
	margin-top: 3vw;
	background-position: center;
	background-size: auto 120%;
	background-repeat: no-repeat;
	padding: 0 30px;
	padding-bottom: 30px;
	display: flex;

	.wrapper {
		background-color: white;
		padding-left: 20px;
		padding-top: 25px;
		padding-bottom: 0;
		width: 100%;
		align-self: flex-end;

		.title {
			font-family: Poppins-Bold;
			font-size: 24px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			color: #000;
		}

		.description {
			width: 100%;
			font-family: Poppins-Regular;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #000;
			padding-top: 20px;
		}

		.buttonWrapper {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
}

@media only screen and (min-width: 992px){
	.home-item {
		height: 38vw;
		min-width: 27vw;
		max-width: 27vw;
		background-color: black;
		margin-right: 4vw;
		margin-top: 3vw;
		background-position: center;
		background-size: auto 120%;
		background-repeat: no-repeat;
		padding: 50px;
		display: flex;

		.wrapper {
			background-color: white;
			padding-left: 30px;
			padding-top: 40px;
			padding-bottom: 0;
			width: 100%;
			align-self: flex-end;

			.title {
				font-family: Poppins-Bold;
				font-size: 2vw;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.11;
				letter-spacing: normal;
				color: #000;
			}

			.description {
				width: 100%;
				font-family: Poppins-Regular;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;
				padding-top: 20px;
			}

			.buttonWrapper {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}
}