.loading-bar{
	position: absolute;
	bottom: 0;
	width: 100%;

	.left{
		border-bottom: 1.2px solid rgba(0,0,0,1);
		transition: all 1s ease;
	}

	.right{
		border-bottom: 1.2px solid rgba(0,0,0,0.3);
		transition: all 1s ease;
	}
}

@media only screen and (min-width: 1200px){
	.loading-bar{
		.left{
			border-bottom: 1.4px solid rgba(0,0,0,1);
		}

		.right{
			border-bottom: 1.4px solid rgba(0,0,0,0.3);
		}
	}
}

@media only screen and (min-width: 1400px){
	.loading-bar{
		.left{
			border-bottom: 1.7px solid rgba(0,0,0,1);
		}

		.right{
			border-bottom: 1.7px solid rgba(0,0,0,0.3);
		}
	}
}