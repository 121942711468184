.chip {
    display: inline-block;
    font-family: Poppins-Bold;
    padding: 8px 15px;
    border-radius: 16.5px;
    color: #fff;
    background-color: #000;
    font-weight: bold;
    font-size: 19px;
    line-height: 1;
}