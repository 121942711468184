.events {
	width: 100%;
	padding-left: 0;
	padding-top: 13%;
	padding-bottom: 10vw;

	.section-title {
		padding-left: 6.5vw;
		.Events-und-Kulturver {
			padding-top: 15%;
			font-family: Poppins-Bold;
			font-size: 30px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.17;
			letter-spacing: normal;
			color: #000;
				width: 100%;
		}

		.Events-und-Kulturver .text-style-1 {
		  	font-weight: 300;
			font-family: Poppins-Light;
		}
	}

	.items {
		width: 100%;
		overflow: auto;
		overflow-y: hidden;
		padding-top: 10%;
		padding-left: 6.5vw;
		padding-right: 6.5vw;
	}
}

@media only screen and (min-width: 992px){
	.events {
		width: 100%;
		padding-top: 5.5vw;
		padding-bottom: 0;

		.section-title {		
			width: 100%;
			padding-left: 10vw;

			.Events-und-Kulturver {
				font-family: Poppins-Bold;
				font-size: 48px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.17;
				letter-spacing: normal;
				color: #000;
			}
			
			.Events-und-Kulturver .text-style-1 {
			  	font-weight: 300;
				font-family: Poppins-Light;
			}
		}

		.items {
			width: 100%;
			overflow: auto;
			overflow-y: hidden;
			padding-top: 0;
			padding-left: 10vw;
			padding-right: 0;
		}
	}
}