.home{
	width: 100%;

	.sub-title{
		font-family: "Univers LT W01_73 Black Ext";
		font-size: 0.625rem;
		letter-spacing: 0.15rem;
		text-transform: uppercase;
		color: #1D0A96;
	}
}

@media only screen and (min-width: 576px){
	.home{
		.sub-title{
			font-size: 0.8rem;
		}
	}
}

@media only screen and (min-width: 992px){
	.home{
		.sub-title{
			font-size: 0.7rem;
		}
	}
}