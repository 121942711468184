.item{
    font-family:Poppins-Bold;
	min-width: 6rem;
	height: 92px;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	
	.wrapper{
		display: flex;
		flex-direction: column;
		justify-content: center;
		cursor: default;
		width: 5rem;
		height: 100%;
		padding: 5px;
		transition: all 0.5s linear;
		.date{
			text-align: center;
			font-size: 0.92rem;
			letter-spacing: 0.28px;
			line-height: 1.375rem;
			transition: all 0.3s linear;
			user-select: none;
		}

		.day{
			text-align: center;
			font-size: 0.92rem;
			line-height: 1.5rem;
			color: #1C266C;
			opacity: 0.3;
			transition: all 0.3s linear;
			user-select: none;
		}
	}

	.wrapper:hover{
		background-color: #264296;
		.date{
			color: white;
		}
		.day{
			opacity: 1;
			color: white;
		}
	}
}

@media only screen and (min-width: 992px){
	.item{
		min-width: 6rem;
		height: 92px;
		margin-left: 10px;
		margin-right: 10px;

    	.wrapper{
			width: 7rem;
			height: 100%;
	    	padding: 5px;
    	}
	}
}