.app-navbar{
	position: fixed;
	background-color: white;
	width: 100%;
	max-width: 1398px;
	padding-top: 1.5625rem;
	padding-bottom: 1.5625rem;
	padding-left: 6vw;
	z-index: 2;
	transition: all .5s ease-out;

	.menu-buttons {
		padding-right: 1.5rem;

		.menu-burger {
			height: 27px;
			width: 27px;
			cursor: pointer;
			transition: all .5s ease-out;
			color: #1D0A96;
		}

		.menu-burger:hover {
			transform: scale(1.1, 1.1);
		}
	}


	.logo{
		display: inline-block;
		width: 8rem;
		height: 2.6375rem;
		background-color: #1D0A96;
		mask: url("/menuLogo.svg");
		mask-size: cover;
		cursor: pointer;
		z-index: 9999;
	}
	
	.nav-icon-hover:hover {
		transform: scale(1.1,1.1);
		transition: all .2s ease-out;
	}

	@keyframes pulse {
		0% {
			transform: scale(0.95);
		}

		50% {
			transform: scale(1.15);
		}

		100% {
			transform: scale(0.95);
		}
	}
}

@keyframes grow{
	0%{
		transform: scale(1);
		background-color: rgba(255,255,255,1);
	}
	70%{
		transform: scale(100);
		background-color: rgba(255,255,255,1);
	}
	100%{
		transform: scale(100);
		background-color: rgba(255,255,255,0);
	}
}

@keyframes shrink{
	0%{
		transform: scale(100);
		background-color: rgba(255,255,255,0);
	}
	30%{
		transform: scale(50);
		background-color: rgba(255,255,255,1);
	}
	100%{
		transform: scale(1);
		background-color: rgba(255,255,255,1);
	}
}

@keyframes growBig{
	0%{
		transform: scale(1);
		background-color: rgba(255,255,255,1);
	}
	50%{
		transform: scale(150);
		background-color: rgba(255,255,255,1);
	}
	70%{
		transform: scale(150);
		background-color: rgba(255,255,255,1);
	}
	100%{
		transform: scale(150);
		background-color: rgba(255,255,255,0);
	}
}

@keyframes shrinkBig{
	0%{
		transform: scale(150);
		background-color: rgba(255,255,255,0);
	}
	40%{
		background-color: rgba(255,255,255,1);
	}
	60%{
		transform: scale(80);
		background-color: rgba(255,255,255,1);
	}
	100%{
		transform: scale(1);
		background-color: rgba(255,255,255,1);
	}
}

.menu-logo{
	display: none;

	img{
		width: 7.5rem;
	}
}

@media only screen and (min-width: 992px){
	.app-navbar{
		padding-right: 10rem;
		padding-top: 2rem;
		padding-bottom: 0rem;
		padding-left: 10vw;

		.menu-buttons {
			padding-bottom: .5rem;
		}


		.logo{
			width: 9rem;
			height: 3.03rem;
			z-index: 999;
		}
	}
}

@media only screen and (min-width: 1200px){
	.app-navbar{
		padding-right: 13rem;
		padding-top: 1.5rem;
		padding-bottom: 1rem;

		.logo{
			width: 9rem;
			height: 3.03rem;
			z-index: 999;
		}
	}
}

@media only screen and (min-width: 1400px){
}