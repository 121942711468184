.calender-result-event {
	padding-top: 5vw;
	padding-bottom: 20vw;
	.result-event-info {
		min-width: 100%;
		padding-left: 3vw;
		padding-right: 3vw;

		.event-info-type {
			font-family: Poppins-Bold;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #000; 
			padding-bottom: 2vw;
		}

		.event-info-author {
			font-family: Poppins-Bold;
			font-size: 31px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.13;
			letter-spacing: normal;
			color: #000;
			padding-bottom: 3vw;
		}

		.event-info-description {
			width: 70%;
			font-family: Poppins-Regular;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #000;	
			padding-bottom: 6vw;
		}

		.event-entry-info {
		    font-family: Poppins-Bold;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.45;
			letter-spacing: normal;
			color: #000;
			padding-bottom: 5vw;
		}
	}

	.result-event-image {
		width: 100%;
		height: 250px;
	}
}

@media only screen and (min-width: 992px){
	.calender-result-event {
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;
		padding-bottom: 75px;

		.result-event-info {
			min-width: 55%;
			width: 55%;
			padding-left: 0;
			padding-right: 0;


			.event-info-type {
				font-family: Poppins-Bold;
				font-size: 16px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000; 
				padding-bottom: .85vw;
			}

			.event-info-author {
				font-family: Poppins-Bold;
				font-size: 31px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.13;
				letter-spacing: normal;
				color: #000;
				padding-bottom: 1.1vw;
			}

			.event-info-description {
				width: 70%;
				font-family: Poppins-Regular;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;	
				padding-bottom: 2.2vw;
			}

			.event-entry-info {
			    font-family: Poppins-Bold;
				font-size: 16px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;
				padding-bottom: 0;
			}
		}

		.result-event-image {
			width: 389px;
			height: 483px;
		}
	}
}