.album {
	width: 100%;
	padding-top: 16vw;

	.album-items {
		transition: all .5s ease-out;
	}

	.album-footer {
		.album-title {
			padding-left: 6vw;
			padding-top: 6vw;
			width: 65%;
		    font-family: Poppins-Bold;
		    text-transform: lowercase;
			font-size: 32px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #000;
		}

		.album-navigation {
			opacity: 0;
		}
	}
}

@media only screen and (min-width: 992px){
	.album {
		padding-left: 10vw;
		padding-top: 6vw;
		width: 100%;

		.album-items {
			width: 100%;
			transition: all .5s ease-out;
		}

		.album-footer {
			min-width: 100%;

			.album-title {
				min-width: 850px;
				font-family: Poppins-Bold;
				font-size: 48px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1;
				letter-spacing: normal;
				color: #000;
				text-transform: lowercase;
				padding-top: 2.9vw;
				padding-bottom: 1vw;
				padding-left: 0;
			}

			.album-navigation {
				min-width: 155px;
				padding-top: 2vw;
				padding-bottom: 1vw;
				opacity: 1;

				.album-left {
					min-width: 65px;
					width: 65px;
					min-height: 65px;
					height: 65px;
  					background-color: rgba(236, 236, 236, 0.78);
				    border-radius:50%;
				    -moz-border-radius:50%;
				    -webkit-border-radius:50%;
				    cursor: pointer;
				}

				.album-right {
					min-width: 65px;
					width: 65px;
					min-height: 65px;
					height: 65px;
				 	background-color: rgba(236, 236, 236, 0.78);
				    border-radius:50%;
				    -moz-border-radius:50%;
				    -webkit-border-radius:50%;
				    cursor: pointer;
				}

				.album-icon {
					transform: scale(1.4,1);
					transition: all .3s ease-out;
				}

				.album-icon:hover {
					transform: scale(1.82,1.3);
				}
			}
		}
	}
}
