.booking {
	width: 100%;

	.booking-nav {
		width: 100%;
		padding-bottom: 70px;
		padding-left: 7vw;
		padding-right: 7vw;
	}

	.booking-part {
		width: 100%;

		.booking-part-image {
			min-width: 100%;
			height: 170px;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items:center;
			cursor: pointer;
			background-position: center;
			background-size: 100%;

			.panorama-icon {
				position: relative;
				height: 100px;
				width: 100px;
				color: #1D0A967A;
				left: 0; 
				right: 0; 
				top: 0;
				bottom: 0;
				animation: pulse 2s infinite;
			}
		}

		.booking-part-text {
			padding-top: 10vw;
			padding-left: 7vw;
			padding-right: 7vw;

			.booking-part-title {  
				padding-bottom: 6vw;
				font-family: Poppins-Bold;
				font-size: 32px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;
			}

			.booking-part-description {
				padding-bottom: 6vw;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.29;
				letter-spacing: normal;
				color: #000;
			}

			.booking-part-btn {
				padding-bottom: 18vw;
			}
		}
	}

	.booking-wide-image {
		min-width: 100%;
		height: 170px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% auto;
	}
	
}

@media only screen and (min-width: 992px){
	.booking {
		width: 100%;

		.booking-nav {
			width: 100%;
			padding-left: 30%;
			padding-right: 30%;
			padding-top: 40px;
			padding-bottom: 40px;
		}

		.booking-part {
			padding-top: 5vw;
			padding-bottom: 1.5vw;
			width: 100%;
			padding-left: 10vw;
			padding-right: 10vw;

			.booking-part-image {
				min-width: 451px;
				height: 560px;
				background-color: black;

				.panorama-icon {
					position: relative;
					height: 150px;
					width: 150px;
					color: #1D0A967A;
					left: 0; 
					right: 0; 
					top: 0;
					bottom: 0;
					animation: pulse 2s infinite;
				}
			}

			.booking-part-text {
				padding-top: 0;
				padding-left: 2.5vw;
				padding-right: 0;

				.booking-part-title {  
					padding-bottom: 1.5vw;
					font-family: Poppins-Bold;
					font-size: 38px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.11;
					letter-spacing: normal;
					color: #000;
					width: 68%;
				}

				.booking-part-description {
					padding-bottom: 1.5vw;
					font-family: Poppins-Regular;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.25;
					letter-spacing: normal;
					color: #000;
					width: 93%;
				}

				.booking-part-btn {
					padding-bottom: 0;
				}
			}
		}

		.booking-wide-image {
			margin-top: 4vw;
			margin-bottom: 5.5vw;
			width: 100%;
			height: 432px;
			background-size: 100%;
		}
	}
}