.info-pager{
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 10vw;
	padding-bottom: 60vw;

	.header{
		position: relative;
		width: 90vw;

		.small-header{
			width: 90vw;
			height: 40px;
			border-radius: 8px;
			border: solid 1px #1d0a96;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-self: center;

			.info-pager-button {
				position: absolute;
				right: 20px;
				width: 20px;
				height: 20px;
				margin-top: auto;
				margin-bottom: auto;
				color: #1d0a96;
			}

			.sub-title{
				font-family: Poppins-Bold;
				padding: 0;
				line-height: 40px;
				height: 100%;
				width: 85%;
				color: #1d0a96;
				align-self: center;
			}

			span{

			}

			img{
				position: absolute;
				right: 1.2rem;
				height: 1rem;
				width: 1rem;
			}
		}
		.topics{
			overflow: hidden;
		}
		.mobile-result{
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 1;
			background-color: white;
			z-index: 99999;
			overflow: hidden;

			.header{
				height: 3.5rem;
				background-color: #E5E5E5;
            	font-family:Poppins-Bold;
				text-align: center;
            	font-size: 14px;
				line-height: 3.5rem;
			}
		}

		ul{
			list-style-type: none;
			padding: 2rem 0;
		}

		li{
			margin-bottom: 1.3rem;
		}
	}

	.result{
		transition: opacity .5s linear;
		.info-image{
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
			height: 20rem;
		}
	}
}

@media only screen and (min-width: 992px){
	.info-pager{
		padding-left: 10vw;
		padding-right: 10vw;
		width: 100%;
		display: block;
		padding-top: 0;
		padding-bottom: 0;

		.header{
			border: none;
			font-family: Poppins-Regular;
			width: auto;
		}

		.result{
			padding: 4rem 0rem;
			margin-bottom: 2rem;
			background-color: white;

			.info-image{
				width: 44%;
				height: 35rem;
				background-size: cover;
			}
		}

		ul{
			list-style-type: none;
			padding: 0;
			margin-bottom: 0;
			width: 56%;
			padding-left: 4vw;

			li{
				margin-bottom: 1rem;
			}
		}
	}
}