.container {
    display: flex;
    flex-direction: column;
    
    &:not(:last-child) {
        margin-bottom: 119px;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}