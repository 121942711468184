.contact{
	.contact-landing{
		width: 100%;
		height: 100vh;
		.contact-title{
			font-family: Poppins-Bold;
			font-size: 2.3rem;
			line-height: 3.5rem;
			letter-spacing: 0.1rem; 
			color: white;
		  	-webkit-text-stroke: 0.8px #274296;
		  	padding-top: 6rem;
		}
	}

	.contact-form{
		padding-top: 3.5rem;
		padding-bottom: 8rem;
		transition: all .5s ease;
		.title{
			font-family: Poppins-Bold;
			font-size: 1.9375rem;
			line-height: 2.75rem;
			padding-bottom: 3rem;
		}
		input{
			font-size:1.3rem;
			margin-bottom: 5vw;
			height: 3rem;
			border: 0;
			border-bottom: 1px solid black;
			padding: 0;
			width: 100%;
		}

		input:focus{
			outline: none;
		}

		.confirmation{
			margin-bottom: 0;
			height: auto;
		}

		input::placeholder{
			font-size: 1rem;
			color: black;
		}
		select{
			margin-bottom: 5vw;
			height: 3rem;
			border: 0;
			border-bottom: 1px solid black;
			outline: 0;
			font-size: 1rem;
			color: black;
			padding: 0;
			width: 100%;
		}
		.anrede-input{
			width: 50%;
			padding: 0;
			border: none;
			margin-bottom: 2rem;
			input{
				position: absolute;
				top: 0;
				margin: 0;
				cursor: default;
			}
			.react-dropdown-select-dropdown-handle{
				margin-top: 10px;	
				cursor: default;
			}
			.react-dropdown-select-content{
				span{
					position: absolute;
					top: 10px;
					font-size:1.3rem;
				}
			}

		}
		.css-12zlm52-ReactDropdownSelect:focus, .css-12zlm52-ReactDropdownSelect:focus-within{
			box-shadow: none;
		}
		.grund-input{
			width: 100%;
			padding: 0;
			border-bottom: 1px solid black;
			margin-bottom: 2rem;
			input{
				position: absolute;
				top: 0;
				margin: 0;
				cursor: default;
			}
			.react-dropdown-select-dropdown-handle{
				margin-top: 10px;	
				cursor: default;
			}
			.react-dropdown-select-content{
				span{
					position: absolute;
					top: 10px;
					font-size:1.3rem;
				}
			}

		}

		.confirmation{
			width: auto;
		}

		textarea{
			margin: 5vw 0; 
			border: 1px solid black;
			font-size: 1rem;
			line-height: 1.2rem;
			padding: 1rem;
			width: 100%;
			font-size:1.3rem;
		}
		textarea::placeholder{
			font-size: 1rem;
			line-height: 1.2vw;
			color: black;
		}

		textarea:focus{
			outline: none;
		}
		.age-info{
			font-size: 0.7rem;
			line-height: 1rem;
			font-style: italic;
			width: 100%;
			padding-left: 2.1rem;
		}
		.submit-text{
			font-family: Poppins-Regular;
			font-size: 0.75rem;
			letter-spacing: -0.0075rem;
			text-transform: uppercase;
			padding-top: 3rem;
		}

		.btn-wrapper{
			position: relative;
			padding: 0;
			padding-top: 2rem;
			width: 10rem;

			.success-message{
				position: absolute;
				bottom: -70px;
				width: 90vw;
			}
		}

		.field-error{
			font-family: Poppins-Regular;
			position: absolute;
			left: 0;
			font-size: 0.9rem;
			bottom: 0px;
			font-style: italic;
			padding-left: 0px;
		}

		.message-error{
			bottom: 7px !important;
		}
	}

	.black-circle, .large-title{
		display: none;
	}


}

@media only screen and (min-width: 450px){
	.contact{
		.contact-landing{
			.contact-title{
				font-size: 3.2rem;
				line-height: 3.25rem;
				letter-spacing: 0.075rem; 
			}
		}
	}
}

@media only screen and (min-width: 576px){
	.contact{
		.contact-landing{
			.contact-title{
				font-size: 4rem;
				line-height: 4.5rem;
				letter-spacing: 0.1rem; 
			}
		}
	}
}

@media only screen and (min-width: 768px){
	.contact{
		.contact-landing{
			.contact-title{
				font-size: 3.8rem;
				line-height: 3.9rem;
				letter-spacing: 0.1rem; 
			}
		}
	}
}

@media only screen and (min-width: 992px){
	.contact{
		opacity: 1;
		
		.col-12{
			padding: 0;
		}

		.contact-landing{
			.contact-title{
				padding-top: 8rem;
				font-size: 4.2rem;
				line-height: 4.5rem;
				letter-spacing: 0.1rem; 
		  		-webkit-text-stroke: 0.7px #274296;
		  		padding-left: 13rem;
		  		padding-right: 11rem;
			}
		}

		.contact-form{
			padding-left: 13rem;
			padding-right: 13rem;

			.anrede-input{
				input{
				}
				.react-dropdown-select-dropdown-handle{
					margin-top: 13px;
					margin-right: 5px;
				}
				.react-dropdown-select-content{
					span{
						font-size:1.2rem;
					}
				}

			}
			.grund-input{
				top: 0;
				
				input{
				}
				.react-dropdown-select-dropdown-handle{
					margin-top: 13px;
					margin-right: 13px;
				}
				.react-dropdown-select-content{
					span{
						font-size:1.2rem;
					}
				}

			}
			.left-padding{
				padding-left: 5%;
			}
			input{
				font-size:1.2rem;
				margin-bottom: 2rem;
				height: 3rem;
				border: 0;
				border-bottom: 1px solid black;
				padding: 0;
				width: 95%;
			}

			.confirmation{
				margin-bottom: 0;
				height: auto;
				width: auto;
			}

			input::placeholder{
				font-size: 0.8rem;
				color: black;
			}

			.anrede-input{
				width: 25%;
			}

			select{
				margin-bottom: 2rem;
				height: 3rem;
				border: 0;
				border-bottom: 1px solid black;
				outline: 0;
				font-size: 0.8rem;
				color: black;
				padding: 0;
				width: 95%;
			}
			textarea{
				margin: 2rem 0; 
				border: 1px solid black;
				font-size: 1.1rem;
				line-height: 1rem;
				padding: 1rem;
				width: 100%;
			}
			textarea::placeholder{
				font-size: 0.8rem;
				line-height: 1rem;
				color: black;
			}
			textarea:focus, input:focus, select:focus{
				outline: none;
			}

			.btn-wrapper{
				position: relative;
				padding-top: 3rem;
				.success-message{
					position: absolute;
					bottom: -50px;
					width: 700px;
				}
			}


			.field-error{
			font-family: Poppins-Regular;
				position: absolute;
				left: 0;
				font-size: 0.9rem;
				bottom: 10px;
				font-style: italic;
			}

			.message-error{
				bottom: 17px !important;
			}

			.age-info{
				padding-left: 3.2rem;
			}

		}
		.black-circle{
			display: initial;
			position: absolute;
			top: 2.2rem;
			right: 15%;
			width: 23px;
			height: 23px;
			transition: all .2s ease;
		}

		.large-title{
			display: initial;
			position: absolute;
			top: 5rem;
			left: 9rem;
			font-family: Poppins-Bold;
			font-size: 0.9rem;
			letter-spacing: 1.8px;
			text-align: left;
		}
	}
}

@media only screen and (min-width: 1200px){
	.contact{
	
		.contact-landing{
			.contact-title{
				font-size: 5rem;
				line-height: 5.5rem; 
		  		padding-left: 16rem;
		  		padding-right: 13rem;
			}
		}

		.contact-form{	
			padding-left: 16rem;
			padding-right: 16rem;
			.anrede-input{
				input{
				}
				.react-dropdown-select-dropdown-handle{
					margin-top: 11px;
					margin-right: 7px;
				}

			}
			.grund-input{
				input{
				}
				.react-dropdown-select-dropdown-handle{
					margin-top: 11px;
					margin-right: 20px;
				}

			}
		}

		.black-circle{
			right: 19rem;
			width: 31px;
			height: 31px;
		}

		.large-title{
			top: 6rem;
			font-size: 1.125rem;
			letter-spacing: 1.8px;
			text-align: left;
			left: 12.5rem;
		}
	}
}

@media only screen and (min-width: 1400px){
	.contact{
		.contact-landing{
			.contact-title{
				font-size: 5.7rem;
				line-height: 5.7rem;
			}
		}
	}
}