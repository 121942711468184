.filter-buttons-container {
    max-width: 450px;
    margin: 70px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filter-button {
    border-radius: 8px;
    padding: 0 20px;
    height: 40px;
    background: none;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
    border: solid 1px #1d0a96;
    font-weight: bold;
    color: #1d0a96;
    cursor: pointer;

    &:not(:last-child) {
        margin: 0 20px 20px 0;
    }

    &-selected {
        @extend .filter-button;
        background-color: #1d0a96;
        color: #fff;
    }
}
