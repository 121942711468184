
.calender {
	width: 100%;

	.calender-text {
		padding-left: 6vw;
		padding-right: 6vw;
		padding-top: 2vw;
		padding-bottom: 15vw;
		width: 100%;  
		font-family: Poppins-Regular;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: #000;
	}

	.calender-widget-container {

		.react-calendar__navigation {
			display: flex;
			flex-direction: row;
			padding-bottom: 7vw;
		}

		.react-calendar__navigation__label{
			padding-left: 0;
			padding-right: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-family: Poppins-Bold;
			font-size: 30px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			text-align: center;
			color: #000;
		}

		.react-calendar__navigation__label__labelText--from {
			width: 90vw

		}

		.react-calendar__navigation__label__divider {
			display: none;
		}

		.react-calendar__navigation__label__labelText--to {
			width: 350px;

		}

		.react-calendar__navigation__label {
			background-color: white;
			border: none;
		}

		.react-calendar__navigation__prev2-button {
			display: none;
		}

		.react-calendar__navigation__next2-button {
			display: none;
		}

		.react-calendar__navigation__prev-button:disabled {
			color: rgba(0, 0, 0, .3);
		}

		.react-calendar__navigation__prev-button {
			background-color: white;
			border: none;
			padding: 0;
			font-size: 25px;
			transform: scale(1.5);
			text-align: left;
			padding-left: 2vw;
			color: black;
		}

		.react-calendar__navigation__next-button {
			background-color: white;
			border: none;
			padding: 0;
			font-size: 25px;
			transform: scale(1.5);
			text-align: right;
			padding-right: 2vw;
			color: black;
		}

		.react-calendar__viewContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.react-calendar__month-view__weekdays {
			padding-bottom: 8.8vw;
		}

		.react-calendar__month-view {
			width: 100%;
		} 

		.react-calendar__month-view__weekdays__weekday {
			text-align: center;
		}

		.react-calendar__month-view__weekdays__weekday abbr{
			text-decoration: none;
			font-family: Poppins-Regular;
			font-size: 14.7px;
			font-weight: normal;
			font-stretch: condensed;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #000;
		}

		.react-calendar__tile--active {
			background-color: #1d0a96 !important;
			color: white !important;
		}

		.react-calendar__tile:hover {
			background-color: #1d0a96;
			color: white;
		}

		.react-calendar__tile abbr {  
			display: none;
		}

		.react-calendar__tile {  
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			width: 56px;
			height: 56px;
			border: solid 0.01px #979797;
			background-color: white;
			transition: all .3s ease-in-out;

			.day-active {
				
			}


			.current-day {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background-color: #1d0a96;
				color: white;
			}

			.day-marker {
				transform: translateY(5px);
				width: 5px;
				height: 5px;
				border: 1px solid #1d0a96;
				background-color: #1d0a96;
				border-radius: 50%;
				align-self: center;
			}
		}

		.react-calendar__month-view__days {
			display: grid;
		}
		.react-calendar__month-view__days__day:disabled {
			border: none;
			font-family: Poppins-Regular;
			font-size: 14.7px;
			font-weight: bold;
			font-stretch: condensed;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #cdcdcd;
		}

		.react-calendar__month-view__days__day {
			border: solid -1px #979797;
			font-family: Poppins-Regular;
			font-size: 14.7px;
			font-weight: bold;
			font-stretch: condensed;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #979797;
		}
	}

	.calender-current-date {
		margin-left: 3vw;
		margin-right: 3vw;
		padding-top: 5vw;
		padding-bottom: 1vw;
	    font-family: Poppins-Bold;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #1d0a96;
		border-bottom: solid 1px #000;
	}

	.calender-result {
		width: 100%;
		padding-top: 2.5vw;
		padding-bottom: 10vw;
	}
}

@media only screen and (min-width: 992px){

	.calender {
		width: 100%;

		.calender-text {
			padding-left: 22vw;
			padding-right: 22vw;
			padding-top: 2vw;
			padding-bottom: 4vw;
			width: 100%;  
			font-family: Poppins-Regular;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			text-align: center;
			color: #000;
		}

		.calender-widget-container {
			padding-left: 15vw;
			padding-right: 15vw;

			.react-calendar__navigation {
				display: flex;
				flex-direction: row;
				padding-bottom: 1.5vw;
			}

			.react-calendar__navigation__label{
				padding-left: 0;
				padding-right: 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				font-family: Poppins-Bold;
				font-size: 38px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.11;
				letter-spacing: normal;
				text-align: center;
				color: #000;
			}

			.react-calendar__navigation__label__labelText--from {
				width: 375px;
			}

			.react-calendar__navigation__label__divider {
				display: none;
			}

			.react-calendar__navigation__label__labelText--to {
				width: 375px;

			}

			.react-calendar__navigation__label {
				background-color: white;
				border: none;
			}

			.react-calendar__navigation__prev2-button {
				display: none;
			}

			.react-calendar__navigation__next2-button {
				display: none;
			}

			.react-calendar__navigation__prev-button {
				background-color: white;
				border: none;
				width: 10px;
				padding: 0;
				font-size: 25px;
				transform: scale(1.5);
				text-align: left;
				padding-left: .2vw;
			}

			.react-calendar__navigation__next-button {
				background-color: white;
				border: none;
				width: 10px;
				padding: 0;
				font-size: 25px;
				transform: scale(1.5);
				text-align: right;
				padding-right: .2vw;
			}

			.react-calendar__viewContainer {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.react-calendar__month-view__weekdays {
				padding-bottom: 2.5vw;
			}

			.react-calendar__month-view {
				width: 392px;
			} 

			.react-calendar__month-view__weekdays__weekday {
				text-align: center;
			}

			.react-calendar__month-view__weekdays__weekday abbr{
				text-decoration: none;
				font-family: Poppins-Regular;
				font-size: 14.7px;
				font-weight: normal;
				font-stretch: condensed;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #000;
			}

			.react-calendar__tile:hover {
				background-color: #1d0a96;
				color: white;
			}

			.react-calendar__tile abbr {  
				display: none;
			}

			.react-calendar__tile {  
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0;
				width: 56px;
				height: 56px;
				border: solid 0.01px #979797;
				background-color: white;
				transition: all .3s ease-in-out;

				.current-day {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					background-color: #1d0a96;
					color: white;
				}

				.day-marker {
					width: 5px;
					height: 5px;
					border: 1px solid #1d0a96;
					background-color: #1d0a96;
					border-radius: 50%;
					align-self: center;
				}
			}

			.react-calendar__month-view__days {
				display: grid;
			}
			.react-calendar__month-view__days__day:disabled {
				border: none;
				font-family: Poppins-Regular;
				font-size: 14.7px;
				font-weight: bold;
				font-stretch: condensed;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #cdcdcd;
			}

			.react-calendar__month-view__days__day {
				border: solid -1px #979797;
				font-family: Poppins-Regular;
				font-size: 14.7px;
				font-weight: bold;
				font-stretch: condensed;
				font-style: normal;
				line-height: 1.25;
				letter-spacing: normal;
				color: #979797;
			}
		}

		.calender-current-date {
			margin-left: 15vw;
			margin-right: 15vw;
			padding-top: 3.5vw;
			padding-bottom: 1vw;
		    font-family: Poppins-Bold;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: normal;
			color: #1d0a96;
			border-bottom: solid 1px #000;
		}

		.calender-result {
			width: 100%;
			padding-left: 15vw;
			padding-right: 15vw;
			padding-top: 2.5vw;
			padding-bottom: 10vw;
		}
	}
}