.info-topic{
	font-family: Poppins-Regular;
	font-size: 16px;
	line-height: 2.5rem;
	letter-spacing: 0.4px;
	color: black;
	opacity: 1;
	cursor: default;
	text-align: center;
}

@media only screen and (min-width: 992px){
	.info-topic{
		font-family: Poppins-Regular;
		font-size: 14px;
		line-height: 2.125rem;
		padding-bottom: .6rem;
		transition: all 0.5s ease;
	}

	.info-topic:hover{
		transform: scale(1.1);
	}
}