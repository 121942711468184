.album-item {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	min-width: 100vw;
	height: 246px;
	background-position: center;

	.album-item-position {
	    width: 60px;
		height: 26px;
		padding: 4px 18px;
		opacity: 0.6;
		border-radius: 13px;
		background-color: #000;
		font-family: Poppins-Regular;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
}

@media only screen and (min-width: 992px){
	.album-item {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		margin-right: 75px;
		min-width: 1010px;
		height: 663px;
		background-repeat: no-repeat;
		background-size: cover;

		.album-item-position {
		    width: 60px;
			height: 26px;
			padding: 4px 18px;
			opacity: 0.6;
			border-radius: 13px;
			background-color: #000;
			font-family: Poppins-Regular;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
		}
	}
}