.filter-item {
	width: 100px;
	height: 40px;
	border-radius: 8px;
	border: solid 1px #1d0a96;
	font-family: Poppins-Bold;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #1d0a96;
	text-align: center;
	vertical-align: middle;
	line-height: 40px;
	cursor: pointer;
}

@media only screen and (min-width: 992px){

	.filter-item {
		width: 160px;
		height: 40px;
		border-radius: 8px;
		border: solid 1px #1d0a96;
		font-family: Poppins-Bold;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #1d0a96;
		text-align: center;
		vertical-align: middle;
		line-height: 40px;
		cursor: pointer;
	}

}

.filter-item:hover {
	background-color: #1d0a96;
	color: white;
}

