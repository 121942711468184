.landing {
	width: 100%;

	.teaser {
		width: 100%;
		padding-left: 6.5vw;
		padding-top: 30%;
		margin: 0;

		.Eventlocation-Munch {
			  width: 80%;

			  font-family: Poppins-Bold;
			  font-size: 33px;
			  font-weight: bold;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.17;
			  letter-spacing: normal;
			  color: #1d0a96;
			.text-style-1 {
		  		font-family: LucidaGrande;
			}
		 	.text-style-2 {
		  		font-weight: 300;
		  		font-family: Poppins-Light
			}
		}
		.Ihr-stilvoller-Veran {
			  padding-top: 10%;
			  width: 90%;
			  font-family: Poppins-Regular;
			  font-size: 14px;
			  font-weight: normal;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.29;
			  letter-spacing: normal;
			  color: #1d0a96;
		}
	}
	
	.image {
		margin-top: 5vh;
		width: 100%;
		height: 35vh;
		background-image: url("https://marstall-am-see.de:8443/siteImages/DESKTOP/HOME_LANDING");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.buttonWrapper {
		padding-top: 5%;
	}
}

@media only screen and (min-width: 992px){
	.landing {
		width: 100%;
		height: 75vh;

		.teaser {
			width: 50%;
			padding-left: 10vw;
			padding-top: 12%;
			margin: 0;

			.Eventlocation-Munch {
				  width: 100%;
				  height: 224px;

				  font-family: Poppins-Bold;
				  font-size: 48px;
				  font-weight: bold;
				  font-stretch: normal;
				  font-style: normal;
				  line-height: 1.17;
				  letter-spacing: normal;
				  color: #1d0a96;
				.text-style-1 {
			  		font-family: LucidaGrande;
				}
			 	.text-style-2 {
			  		font-weight: 300;
			  		font-family: Poppins-Light
				}
			}
			.Ihr-stilvoller-Veran {
				  padding-top: 4%;
	  			  width: 70%;
				  height: 80px;
				  font-family: Poppins-Regular;
				  font-size: 16px;
				  font-weight: normal;
				  font-stretch: normal;
				  font-style: normal;
				  line-height: 1.25;
				  letter-spacing: normal;
				  color: #1d0a96;
			}
		}
		
		.image {
			width: 50%;
			margin-top: 7%;
			height: 75%;
			background-image: url("https://marstall-am-see.de:8443/siteImages/DESKTOP/HOME_LANDING");
			background-repeat: no-repeat;
		}

		.buttonWrapper {
			padding-top: 70px;
		}
	}
}