.impressum{
	height: 100vh;
}

.impressum-title{
	font-family: Poppins-Bold;
	padding-top: 35vw;
	font-size: 8vw;
	font-weight: 900;
	text-align: center;
}

.impressum-address{
	font-family: Poppins-Regular;
	padding-top: 20vw;
}

.impressum-contact{
	padding-top: 15vw;
	padding-bottom: 50vw;
	font-family: Poppins-Regular;
}	

.black-circle{
	display: none;
}

@media only screen and (min-width: 992px){
	.impressum{
		height: 100vh;
		overflow-y: hidden;
	}

	.impressum-title{
		padding-top: 10rem;
		text-align: left;
		font-family: Poppins-Bold;
		font-size: 1.5rem;
		letter-spacing: 1.8px;
		padding-left: 10vw;
	}

	.impressum-address{
		font-family: Poppins-Regular;
		padding-top: 4rem;
		margin-left: 6rem;
	}

	.impressum-contact{
		font-family: Poppins-Regular;
		padding-top: 3rem;
		margin-left: 6rem;
	}

	.black-circle{
		display: initial;
		position: absolute;
		top: 3rem;
		right: 15%;
		width: 25px;
		height: 25px;
		transition: all .2s ease;
	}

	.black-circle:hover{
		transform: scale(1.07);
	}
}