.container {
    @media screen and (min-width: 768px) {
        margin-left: 50px;
        flex: 1;
    }
}

.price {
    margin-bottom: 20px;
}

.title {
    font-size: 38px;
    font-family: Poppins-Bold;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 30px;
}

.description {
    font-size: 16px;
    font-family: Poppins-Regular;
    line-height: 1.25;
    margin-bottom: 20px;
}

.more-btn {
    font-size: 16px;
    font-family: Poppins-Bold;
    font-weight: bold;
    line-height: 1.25;
    border: none;
    background: none;
    padding: 0;
    border-bottom: 1px solid #000;
    margin-bottom: 46px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.modal-title {
    font-size: 25px;
    margin-right: 30px;
}

.flat-picture {
    width: 100%;
    max-height: 400px;
    background-position: center;
    object-fit: cover;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
