.menu {
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
	min-width: 100vw;
	background-color: white;
	z-index: 9999999;
	animation-name: appear;
	animation-duration: .3s;
	animation-timing-function: linear;
	animation-iteration-count: 1;

	.header-wrapper {
		width: 100vw;
		padding-left: 6vw;
		padding-right: 5.4vw;
		padding-top: 1.5rem;

		.logo{
			width: 8rem;
			height: 2.637rem;
			background-color: #1D0A96;
			mask: url("/menuLogo.svg");
			mask-size: cover;
			cursor: pointer;
		}

		.close-button-wrapper {
			padding-top: 2.1vw;
		}

		.close-button {
			height: 35px;
			width: 35px;
			color: #1D0A96;
			cursor: pointer;
		}

		a {
			padding-top: 3vw;
		}

		.menu-facebook {
			width : 27px;
			height : 27px;
			color: #1D0A96;
			margin-right : 10px;
			cursor : pointer;
		}

		.menu-instagram {
			min-width : 27px;
			min-height : 27px;
			color: #1D0A96;
			margin-right : 8px;
			cursor : pointer;	
		}
	}

	.items-container {
		width: 100%;
		padding-left: 14vw;
		padding-right: 14vw;
		padding-top: 15vw;
		padding-bottom: 25vw;
		margin-left: auto;
		margin-right: auto;

		.menu-item {
			width: 100%;
			font-family: Poppins-Light;
			font-size: 32px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			text-align: center;
			color: #1d0a96;
		}

		.menu-item:hover {
			text-decoration: none;
		}
	}
}

@keyframes appear{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}


@media only screen and (min-width: 992px){
	.menu {
		position: fixed;
		top: 0;
		left: 0;
		min-height: 100vh;
		min-width: 100vw;
		width: 1400px;
		background-color: white;
		z-index: 9999999;
		animation-name: appear;
		animation-duration: .3s;
		animation-timing-function: linear;
		animation-iteration-count: 1;

		.header-wrapper {
			max-width: 1400px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 10vw;
			padding-right: 14rem;
			padding-top: 1.5rem;

			.logo{
				width: 9rem;
				height: 3.03rem;
				background-color: #1D0A96;
				mask: url("/menuLogo.svg");
				mask-size: cover;
				cursor: pointer;
			}

			.close-button-wrapper {
				padding-top: 0;
			}
			.close-button {
				margin-top: .5rem;
				height: 35px;
				width: 35px;
				color: #1D0A96;
				cursor: pointer;
			}

			a {
				padding-top: 0;
			}

		}

		.items-container {
			max-width: 1400px;
			width: 100%;
			padding-left: 10vw;
			padding-right: 13rem;
			padding-top: 6vw;
			padding-bottom: 13vw;
			margin-left: auto;
			margin-right: auto;

			.menu-item {
				width: auto;
				font-family: Poppins-Light;
				font-size: 48px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.63;
				letter-spacing: normal;
				color: #1d0a96;
			}

			.menu-item:hover {
				text-decoration: none;
			}

			.menu-item:hover {
				font-family: Poppins-Bold;
				cursor: pointer;
				text-decoration: none;
			}
		}
	}
}