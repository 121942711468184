.home-calender{
	height: 92px;
	border-top: .6px solid #000000;
	border-bottom: .6px solid #000000;
	z-index: 998;
	margin-top: 20vw;
	
	.left{
		cursor: pointer;
		transition: all .15s ease-in-out;
		.arrow{
			width: 3rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 1rem;
			transform: rotate(90deg);
		}
		z-index: 9999
	}

	.left:hover{
		transform: scale(1.5, 1.5);
	}

	.wrapper{
		position: relative;
		width: 18rem;
		overflow: hidden;
		.days{	
			position: absolute;
			top: 1px;
			left: 0;
			display: flex;
			flex-direction: row;
			transition: all .8s ease-in-out;
		}
	}

	.right{
		cursor: pointer;
		transition: all .15s ease-in-out;
		.arrow{
			width: 3rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 1rem;
			transform: rotate(-90deg);
		}
		z-index: 9999
	}

	.right:hover{
		transform: scale(1.5, 1.5);
	}

	.left-gradient{
		position: absolute;
		left: 0;
		transform: translateY(-5px);
		height: 100px;
		width: calc((100vw - 15rem)/2);
		z-index: 9998;
  		background-image: linear-gradient(to left, rgba(255,255,255, 0) , rgba(255,255,255, 1));
	}

	.right-gradient{
		position: absolute;
		right: 0;
		transform: translateY(-5px);
		height: 100px;
		width: calc((100vw - 15rem)/2);
		z-index: 9998;
  		background-image: linear-gradient(to right, rgba(255,255,255, 0) , rgba(255,255,255, 1));
	}
}

@media only screen and (min-width: 992px){
	.home-calender{
		height: 92px;
		margin-top: 0;

		.wrapper{
			width: 63rem;
			.days{
				top: 0;
			}
		}
	}
	.calender-title{
		margin-top: 7rem;
	}
}