.checkbox-image{
	position: absolute;
	height: 25px;
	width: 25px;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all .5s ease;
	cursor: pointer;

	img{
		transition: all .3s ease;
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		max-height: 100%;
	}
}

.checkbox-image:hover{
	transform: scale(1.1, 1.1);
}